import {
  SET_CRITICAL_DEVICEDATA,
  SET_DATALOG_DEVICEDATA,
  SET_DEVICES,
  SET_DATALOG_DEVICEDATA_BY_ID,
  SET_MAP_DATA,
  SET_CRITICAL_DEVICEDATA_COLUMNS,
  SET_DATALOG_DEVICEDATA_COLUMNS,
  SET_DATALOG_DEVICEDATA_COUNT,
  SET_MAP_DATA_LOADER,
} from '../constants/action-types';
import { convertColumnToRealName } from '../lib/convertColumnsToRealLanguage';
import {
  getCriticalDeviceDataApiCall,
  getDeviceDatalogApiCall,
  getDeviceDatalogByIdApiCall,
  getDevicesDataApiCall,
  getMapDataApiCall,
} from '../services/devices';

const hiddenFields = ['TotalCount', 'GeoCoords'];

export const getCriticalDataCol = (signal) => {
  return async (dispatch) => {
    try {
      const page = 0;
      const limit = 1;
      const apiRes = await getCriticalDeviceDataApiCall(page, limit, signal);
      const criticalDevicesData = apiRes.data;
      const tempColumns = [];
      const objectKeys = Object.keys(criticalDevicesData[0]);
      objectKeys &&
        objectKeys.map((key) => {
          if (!hiddenFields.includes(key)) {
            let columnRealName = convertColumnToRealName(key);
            let columnSize = columnRealName.length * 10;
            if (
              columnRealName.toLowerCase().includes('time') &&
              !columnRealName.toLowerCase().includes('timer')
            ) {
              columnSize = 270;
            }
            if (columnRealName.toLowerCase().includes('imei')) {
              columnSize = 170;
            }
            tempColumns.push({
              headerName: columnRealName,
              field: key,
              width: columnSize < 150 ? 150 : columnSize,
            });
          }
        });
      dispatch({ type: SET_CRITICAL_DEVICEDATA_COLUMNS, payload: tempColumns });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getDataLogCol = (signal, fetchTotalRows = false) => {
  return async (dispatch) => {
    try {
      const page = 0;
      const limit = 1;
      const apiRes = await getDeviceDatalogApiCall(page, limit, signal, fetchTotalRows);
      const criticalDevicesData = apiRes.data;
      const tempColumns = [];
      const objectKeys = Object.keys(criticalDevicesData[0]);
      objectKeys &&
        objectKeys.map((key) => {
          if (!hiddenFields.includes(key)) {
            let columnRealName = convertColumnToRealName(key);
            let columnSize = columnRealName.length * 10;
            if (
              columnRealName.toLowerCase().includes('time') &&
              !columnRealName.toLowerCase().includes('timer')
            ) {
              columnSize = 270;
            }
            if (columnRealName.toLowerCase().includes('imei')) {
              columnSize = 170;
            }
            tempColumns.push({
              headerName: columnRealName,
              field: key,
              width: columnSize < 150 ? 150 : columnSize,
            });
          }
        });
      if (fetchTotalRows) {
        dispatch({
          type: SET_DATALOG_DEVICEDATA_COUNT,
          payload: criticalDevicesData[0]['TotalCount'],
        });
      }
      dispatch({ type: SET_DATALOG_DEVICEDATA_COLUMNS, payload: tempColumns });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getMapData = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getMapDataApiCall();
      dispatch({ type: SET_MAP_DATA, payload: apiRes.data });
      dispatch({ type: SET_MAP_DATA_LOADER, payload: true });
    } catch (error) {
      // handle when getMapData failed.
      dispatch({ type: SET_MAP_DATA_LOADER, payload: true });
    }
  };
};

export const getDatalogById = (id) => {
  return async (dispatch) => {
    try {
      const apiRes = await getDeviceDatalogByIdApiCall(id);
      dispatch({ type: SET_DATALOG_DEVICEDATA_BY_ID, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

export const getDevices = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getDevicesDataApiCall();
      dispatch({ type: SET_DEVICES, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCriticalDataCol } from '../../actions/devices';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { PropagateLoader } from 'react-spinners';

import './style.css';
import { getCriticalDeviceDataApiCall } from '../../services/devices';
import DownloadCSVHeaderRow from '../Utils/DownloadCSVHeaderRow';

export default function ViewCriticalDeviceData() {
  const criticalDataCol = useSelector((state) => state.devices.criticalDataCol);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingColumns, setLoadingColumns] = useState(true);
  const abortControllerRef = useRef(new AbortController());

  const fetchRows = async (page, pageSize) => {
    setLoadingData(true);
    try {
      const response = await getCriticalDeviceDataApiCall(
        page,
        pageSize,
        abortControllerRef.current.signal,
      );
      const { data } = response;
      setRows(data);
      if (data.length) {
        const totalCount = data[0]['TotalCount'];
        setTotalCount(totalCount);
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoadingData(false);
      setLoadingColumns(false);
    }
  };

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    fetchRows(page, pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    if (!criticalDataCol?.length) {
      dispatch(getCriticalDataCol(abortControllerRef.current.signal));
    } else {
      setLoadingColumns(false);
    }
  }, [criticalDataCol?.length, dispatch]);

  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getCriticalDeviceDataApiCall(
        0,
        totalCount,
        abortControllerRef.current.signal,
        from,
        to,
      );
      setDownloading(false);
      return res.data.map((d) => {
        delete d['GeoCoords'];
        return d;
      });
    },
    [totalCount],
  );

  return (
    <div>
      <DownloadCSVHeaderRow
        data={onDownloadClick}
        loading={downloading}
        filename={'critical-data.csv'}
        showFilter={true}
      />
      <Paper sx={{ height: '100%' }} elevation={3}>
        {loadingColumns ? (
          <PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />
        ) : criticalDataCol?.length ? (
          <DataGrid
            rows={rows}
            columns={criticalDataCol}
            rowCount={totalCount}
            paginationModel={{ page: page, pageSize: pageSize }}
            paginationMode="server"
            onPaginationModelChange={(newPageModel) => {
              setPage(newPageModel.page);
              setPageSize(newPageModel.pageSize);
            }}
            loading={loadingData}
            getRowId={(row) => {
              if (row.date && row.IMEINumber) {
                return row.IMEINumber + row.date;
              } else {
                return Math.random().toString(16).slice(2);
              }
            }}
            initialState={{ pagination: { page: 0, pageSize: pageSize } }}
            pageSizeOptions={[15, 30, 45, 60]}
            density={'comfortable'}
            autoHeight={true}
            rowSelection={false}
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5 !important',
                fontWeight: 'bold !important',
                fontSize: '16px',
                padding: '8px',
              },
              '.MuiDataGrid-root': {
                border: 'none !important',
                borderRadius: '20px !important',
              },
              '.MuiDataGrid-footerContainer': {
                backgroundColor: '#f5f5f5 !important',
              },
              '.MuiDataGrid-toolbarContainer ': {
                backgroundColor: '#f5f5f5 !important',
                padding: '8px',
              },
              '.MuiTablePagination-displayedRows': {
                marginTop: '1em',
                marginBottom: '1em',
              },
              '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                marginTop: '1em',
                marginBottom: '1em',
              },
            }}
          />
        ) : (
          <div className="Center">No data available</div>
        )}
      </Paper>
    </div>
  );
}
